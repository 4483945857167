import './app.element.scss';

import { from } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { environment } from '../environments/environment';
import { IRegistrationUrlDto } from './utils/http.dto';
import { init3dPreview } from './utils/three';
import { mobileAppLinks } from './utils/ui';

export class TnLandingRootElement extends HTMLElement {
  public static observedAttributes = [];

  private fetchSignupUrl() {
    const registrationUrl = `${window.location.protocol}//${environment.apiDomain}/digital-profile/registration-url`;
    const response = from(
      window
        .fetch(registrationUrl, {
          method: 'GET',
          redirect: 'follow',
        })
        .then(res => res.json() as Promise<IRegistrationUrlDto>)
        .catch(error => {
          return { url: '' };
        }),
    ).pipe(map(res => res.url));
    return response;
  }

  private getWelcomSectionHtml(title: string, appLink: string, signupUrl: string): string {
    return `<section id="welcome">
      <div class="cell">
        <img src="/assets/images/logo.svg" alt="${title}" class="logo" />
        <p>Электронная транспортная платформа нового поколения</p>
        <div id="preview-container">
          <img src="/assets/images/car.svg" alt="" id="static-preview" />
        </div>

        <div class="inline-buttons">
          <a href="${appLink}" class="button" id="enter">Войти в систему</a>
          ${signupUrl ? `<a href="${signupUrl}" class="button" id="signup">Зарегистрироваться</a>` : ''}
        </div>

        <section class="app-links">
          <div>
            <div class="link-title">Приложение для Водителя</div>
            <div class="links">
              <a id="google-play-link-driver" target="_blank">
                <img class="google-play" src="/assets/images/google-play.svg" alt="google play" />
              </a>
              <a id="app-store-link-driver" target="_blank">
                <img class="app-store" src="/assets/images/app-store.svg" alt="app store" />
              </a>
            </div>
          </div>
        </section>
      </div>
    </section>`;
  }

  private setMobileAppLinks() {
    const googleLinkDriver = document.getElementById('google-play-link-driver');
    const appleLinkDriver = document.getElementById('app-store-link-driver');
    const googleLinkDispatcher = document.getElementById('google-play-link-dispatcher');
    const appleLinkDispatcher = document.getElementById('app-store-link-dispatcher');

    const links = mobileAppLinks();

    googleLinkDriver?.setAttribute('href', links.google.driver);

    appleLinkDriver?.setAttribute('href', links.apple.driver);

    googleLinkDispatcher?.setAttribute('href', links.google.dispatcher);
    appleLinkDispatcher?.setAttribute('href', links.apple.dispatcher);
  }

  public connectedCallback(): void {
    void this.fetchSignupUrl()
      .pipe(
        tap(url => {
          const title = 'TRANSPORT 2';
          const appLink = 'https://tms.transport2.ru';
          this.innerHTML = `
          ${this.getWelcomSectionHtml(title, appLink, url)}
          `;
          this.setMobileAppLinks();
          init3dPreview();
        }),
      )
      .subscribe();
  }
}
customElements.define('transport-landing-root', TnLandingRootElement);
