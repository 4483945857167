import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { PerspectiveCamera } from 'three/src/cameras/PerspectiveCamera';
import { PlaneGeometry } from 'three/src/geometries/PlaneGeometry';
import { LoadingManager } from 'three/src/loaders/LoadingManager';
import { TextureLoader } from 'three/src/loaders/TextureLoader';
import { Material } from 'three/src/materials/Material';
import { MeshBasicMaterial } from 'three/src/materials/MeshBasicMaterial';
import { MeshMatcapMaterial } from 'three/src/materials/MeshMatcapMaterial';
import { Color } from 'three/src/math/Color';
import { Group } from 'three/src/objects/Group';
import { Mesh } from 'three/src/objects/Mesh';
import { WebGLRenderer } from 'three/src/renderers/WebGLRenderer';
import { Scene } from 'three/src/scenes/Scene';
import { OrbitControls } from 'three-orbitcontrols-ts';

import { IPlaneConfig } from '../interfaces/three-config.interface';
import { WEBGL } from './webgl';

let scene: Scene;
let camera: PerspectiveCamera;
let controls: OrbitControls;
let renderer: WebGLRenderer;
let container: HTMLElement | null = null;
let staticPreview: HTMLElement | null = null;
let mainScene: Group;
let textureLoader: TextureLoader;

const planeConfig: IPlaneConfig = {
  geometry: {
    height: 60,
    width: 60,
  },
  position: {
    x: 0,
    y: -1.8,
    z: 0,
  },
};

export function init3dPreview() {
  const manager = new LoadingManager(render);
  textureLoader = new TextureLoader(manager);

  container = document.getElementById('preview-container');
  staticPreview = document.getElementById('static-preview');

  if (!container || !staticPreview || !WEBGL.isWebGLAvailable()) {
    // eslint-disable-next-line no-console -- needed error logging
    console.warn('Can not init preview!');
    return;
  }

  const CAMERA_FOV = 60;
  const CAMERA_NEAR = 0.1;
  const CAMERA_FAR = 100;
  const CAMERA_X = -27.291;
  const CAMERA_Y = -1.309;
  const CAMERA_Z = -25.69;
  const CAMERA_TRANSLATE_X = -3;

  scene = new Scene();
  scene.background = new Color('#c4e1ff');

  const loader = new GLTFLoader();

  loader.load(
    './assets/models/truck.glb',
    gltf => {
      mainScene = gltf.scene;
      scene.add(gltf.scene);
      scene.translateX(CAMERA_TRANSLATE_X);

      // set up camera
      camera = new PerspectiveCamera(
        CAMERA_FOV,
        (staticPreview?.offsetWidth ?? 1) / (staticPreview?.offsetHeight ?? 1),
        CAMERA_NEAR,
        CAMERA_FAR,
      );
      camera.position.set(CAMERA_X, CAMERA_Y, CAMERA_Z);
      camera.updateProjectionMatrix();

      //set up lights
      /*
      const ambientLight = new AmbientLight("white", 0.4);
      scene.add(ambientLight);

      const pointLight1 = new PointLight("white", 1.2);
      pointLight1.position.set(70, 140, -150);
      scene.add(pointLight1);

      const pointLight2 = new PointLight("white", 0.4);
      pointLight2.position.set(-70, -15, 120);
      scene.add(pointLight2);

      const pointLight3 = new PointLight("white", 0.5);
      pointLight3.position.set(170,50,150);
      scene.add(pointLight3);

      const pointLight4 = new PointLight("white", 0.4);
      pointLight4.position.set(150,5,-150);
      scene.add(pointLight4);

      const pointLight5 = new PointLight("white", 0.8);
      pointLight5.position.set(0,100,0);
      scene.add(pointLight5);*/

      // apply materials

      const sceneRoot = gltf.scene.children[0] as Mesh;

      replaceMaterialWithMatcap(sceneRoot, 'red', 'RED.png');
      replaceMaterialWithMatcap(sceneRoot, 'blue', 'BLUE2.png', 'flakes.png');
      replaceMaterialWithMatcap(sceneRoot, 'black', 'BLACK.png');
      replaceMaterialWithColor(sceneRoot, 'white', 'white');
      replaceMaterialWithMatcap(sceneRoot, 'gray', 'CHROME.png');
      replaceMaterialWithMatcap(sceneRoot, 'lightgray', 'CHROME.png');
      replaceMaterialWithColor(sceneRoot, 'orange', '#ffa000');

      // fake shadow plane

      const planeGeometry = new PlaneGeometry(planeConfig.geometry.width, planeConfig.geometry.height);

      const shadowTexture = textureLoader.load('assets/textures/shadow.png');

      const shadowMaterial = new MeshBasicMaterial({
        color: 'white',
        map: shadowTexture,
      });

      const plane = new Mesh(planeGeometry, shadowMaterial);
      const rotateAngleX = -1.5708;
      plane.rotateX(rotateAngleX);

      plane.position.set(planeConfig.position.x, planeConfig.position.y, planeConfig.position.z);
      sceneRoot.add(plane);

      // applyMatcapByName(sceneRoot, 'platform', 'gray.jpg');

      // set up renderer
      renderer = new WebGLRenderer({ antialias: true });

      // set up controls
      controls = new OrbitControls(camera, renderer.domElement);
      controls.enablePan = false;
      controls.enableZoom = false;
      controls.enableDamping = true;
      controls.autoRotate = true;
      controls.enableRotate = true;
      controls.autoRotateSpeed = -0.125;
      controls.rotateSpeed = 0.1;
      controls.dampingFactor = 0.05;
      controls.minPolarAngle = 1;
      controls.maxPolarAngle = 1.55;

      renderer.domElement.classList.add('preview-canvas');
      refreshCanvas();

      document.getElementById('preview-container')?.appendChild(renderer.domElement);
      setTimeout(() => {
        renderer.domElement.style.opacity = '1';
      });

      window.addEventListener('resize', resizePreview);
      animate3dPreview();
    },
    event => {
      // console.log(event);
    },
    error => {
      // console.error(error);
    },
  );
}

export function applyMaterial(mesh: Mesh, material: MeshBasicMaterial | MeshMatcapMaterial) {
  mesh.material = material;
  mesh.material.needsUpdate = true;
}

export function applyMaterialByName(parent: Mesh, name: string, material: MeshBasicMaterial | MeshMatcapMaterial) {
  if (parent.name === name) {
    applyMaterial(parent, material);
  }

  parent.children.forEach(c => applyMaterialByName(c as Mesh, name, material));
}

export function applyMatcapByName(parent: Mesh, name: string, textureName: string, bumpName: string) {
  const material = createMatcapMaterial(textureName, bumpName);
  applyMaterialByName(parent, name, material);
  return material;
}

export function replaceMaterialWithName(parent: Mesh, name: string, material: MeshBasicMaterial | MeshMatcapMaterial) {
  if (Boolean(parent.material) && (parent.material as Material).name === name) {
    applyMaterial(parent, material);
  }

  parent.children.forEach(c => replaceMaterialWithName(c as Mesh, name, material));
}

export function createMatcapMaterial(textureName: string, bumpName?: string) {
  const texture = textureLoader.load(`assets/textures/${textureName}`);
  let bump: ReturnType<typeof textureLoader.load> | null = null;
  if (Boolean(bumpName)) {
    bump = textureLoader.load(`assets/textures/${bumpName}`);
  }

  const material = new MeshMatcapMaterial({
    color: 'white',
    matcap: texture,
    bumpMap: bump,
    bumpScale: 0.005,
  });
  return material;
}

export function replaceMaterialWithMatcap(parent: Mesh, name: string, textureName: string, bumpName?: string) {
  const material = createMatcapMaterial(textureName, bumpName);
  replaceMaterialWithName(parent, name, material);
  return material;
}

export function replaceMaterialWithColor(parent: Mesh, name: string, color = 'white') {
  const material = new MeshBasicMaterial({ color });

  replaceMaterialWithName(parent, name, material);
  return material;
}

export function animate3dPreview() {
  requestAnimationFrame(animate3dPreview);
  render();

  mainScene.position.set(0, 0, 0);
  controls.update();
}

export function render() {
  renderer.render(scene, camera);
}

export function resizePreview() {
  if (!container || !staticPreview) {
    return;
  }
  refreshCanvas();
}

export function refreshCanvas() {
  renderer.setSize(container?.offsetWidth ?? 1, container?.offsetHeight ?? 1);
  renderer.setViewport(0, 0, container?.offsetWidth ?? 1, container?.offsetHeight ?? 1);
  camera.aspect = (container?.offsetWidth ?? 1) / (container?.offsetHeight ?? 1);
  camera.updateProjectionMatrix();
}
