interface IMobileLinks {
  google: {
    driver: string;
    dispatcher: string;
  };
  apple: {
    driver: string;
    dispatcher: string;
  };
}

export function mobileAppLinks() {
  const result: IMobileLinks = {
    google: {
      driver: 'https://play.google.com/store/apps/details?id=ru.transport_2.driver',
      dispatcher: 'https://play.google.com/store/apps/details?id=ru.transport_2.manager',
    },
    apple: {
      driver: 'https://apps.apple.com/us/app/transport2-ru-водитель/id1530008555',
      dispatcher: 'https://apps.apple.com/us/app/transport2-ru-диспетчер/id1530008953',
    },
  };
  return result;
}
