/**
 * @author alteredq / http://alteredqualia.com/
 * @author mrdoob / http://mrdoob.com/
 */

export const WEBGL = {
  isWebGLAvailable: function () {
    try {
      const canvas = document.createElement('canvas');
      return Boolean(Boolean(window.WebGLRenderingContext) && (canvas.getContext('webgl') || canvas.getContext('experimental-webgl')));
    } catch (e) {
      return false;
    }
  },

  isWebGL2Available: function () {
    try {
      const canvas = document.createElement('canvas');
      // eslint-disable-next-line compat/compat -- Safari 13 and iOS Safari 13.0-13.1 users are not among target browsers
      return Boolean(window.WebGL2RenderingContext) && canvas.getContext('webgl2');
    } catch (e) {
      return false;
    }
  },

  getWebGLErrorMessage: function () {
    const version = 1;
    return this.getErrorMessage(version);
  },

  getWebGL2ErrorMessage: function () {
    const version = 2;
    return this.getErrorMessage(version);
  },

  getErrorMessage: function (version: number) {
    const names = {
      1: 'WebGL',
      2: 'WebGL 2',
    };

    const contexts = {
      1: window.WebGLRenderingContext,
      // eslint-disable-next-line compat/compat -- Safari 13 and iOS Safari 13.0-13.1 users are not among target browsers
      2: window.WebGL2RenderingContext,
    };

    let message =
      'Your $0 does not seem to support <a href="http://khronos.org/webgl/wiki/Getting_a_WebGL_Implementation" style="color:#000">$1</a>';

    const element = document.createElement('div');
    element.id = 'webglmessage';
    element.style.fontFamily = 'monospace';
    element.style.fontSize = '13px';
    element.style.fontWeight = 'normal';
    element.style.textAlign = 'center';
    element.style.background = '#fff';
    element.style.color = '#000';
    element.style.padding = '1.5em';
    element.style.width = '400px';
    element.style.margin = '5em auto 0';

    if (Boolean(contexts[version])) {
      message = message.replace('$0', 'graphics card');
    } else {
      message = message.replace('$0', 'browser');
    }

    message = message.replace('$1', names[version]);

    element.innerHTML = message;

    return element;
  },
};
